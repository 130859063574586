
export default function About() {

    return (
      <section className="about">
        <div class="blur"></div>
        <div className="about-text">
          <p>Hei!</p>
          <p>Jeg heter Sylvio og driver sjappa her (for det meste alene). Jeg har jobbet med kommunikasjon og innholdsproduksjon i over ti år, og har gleder av å være midt imellom akademia og produksjon. Ta gjerne kontakt hvis du trenger hjelp med et prosjekt eller bare vil ta en kaffekopp!</p>

          <a href="mailto:sylvio@ccomm.no"><button>Mail</button></a>
          <a href="https://www.linkedin.com/in/sylvio-carvalho/" target='_blank' rel='noreferrer'><button>LinkedIn</button></a>
        </div>
      </section>
    );
  
  }
  
  
  