import OutsideClickHandler from 'react-outside-click-handler';
import { motion } from 'framer-motion';


export default function Film({triggerFilm}) {
  
    return (

        <motion.div 
        className="popup"
        initial={{ 
          opacity: 0,
          transform: "scale(1.05)",
          filter: "blur(3px)"
         }}
        animate={{ 
          opacity: 1,
          transform: "scale(1)",
          filter: "blur(0)",
          transition: { duration: 0.3 } }}
        exit={{ 
          opacity: 0,
          transform: "scale(1.05)",
          filter: "blur(3px)",
          transition: { duration: 0.3 } } }
        >

            <OutsideClickHandler
              onOutsideClick={() => {
              triggerFilm();
              }}
            >
              <div className="popup-content">
                  <p>Videoproduksjon - alt fra manus, produksjon og animasjon.</p>
                  <div className="video-container">
                    <a href='https://vimeo.com/805484312' target='_blank' rel='noreferrer'><div className="video-1"></div></a>
                    <a href='https://youtu.be/K_F7PsBM74c' target='_blank' rel='noreferrer'><div className="video-2"></div></a>
                    <a href='https://youtu.be/xhz8pE-sQsg' target='_blank' rel='noreferrer'><div className="video-3"></div></a>
                  </div>
                  <button className='exit' onClick={ triggerFilm }>×</button>
             </div>
            </OutsideClickHandler>
          </motion.div>
       

    );
  
  }
  
  
  