import OutsideClickHandler from 'react-outside-click-handler';
import { motion } from 'framer-motion';

import { useState } from 'react';

export default function Grafisk({triggerGrafisk}) {

  /* Funksjonalitet */

  const [ profileActive, setProfileActive ] = useState (false); 
/*   const [ documentActive, setDocumentActive ] = useState (false); */
  const [ logoActive, setLogoActive ] = useState (false); 

  const profileActivate = e => {
    setProfileActive (current => !current)
  }
/*   const documentActivate = e => {
    setDocumentActive (current => !current)
  } */
  const logoActivate = e => {
    setLogoActive (current => !current)
  } 



    return (

      /* Motion */

        <motion.div 
        className="popup"
        initial={{ 
          opacity: 0,
          transform: "scale(1.05)",
          filter: "blur(3px)"
         }}
        animate={{ 
          opacity: 1,
          transform: "scale(1)",
          filter: "blur(0)",
          transition: { duration: 0.3 } }}
        exit={{ 
          opacity: 0,
          transform: "scale(1.05)",
          filter: "blur(3px)",
          transition: { duration: 0.3 } } }
        >

            <OutsideClickHandler
              onOutsideClick={() => {
              triggerGrafisk();
              }}
            >








              <div className="popup-content-grafisk">

                <div className={ profileActive || /* documentActive || */ logoActive ? 'is-left grafisk-menu' : 'grafisk-menu' }>
                   <p onClick={ profileActivate }>Grafisk profil</p>
                   {/* <p onClick={ documentActivate }>Oppsett av dokument</p> */}
                   <p onClick={ logoActivate }>Logo</p>
                </div>










                <div className={ profileActive ? 'grafisk-profil' : 'grafisk-profil is-right' }>
                  <p>Fra forretningsidé til visuell identitet</p>
                  
                  <div className="profil-container">
                    <a href='https://ccomm.no/profiler/Sneipfritt.pdf' target='_blank' rel='noreferrer'><div className="profil-1"></div></a>
                    <a href='https://ccomm.no/profiler/Svale.pdf' target='_blank' rel='noreferrer'><div className="profil-2"></div></a>
                  </div>

                  <p className='arrow-back' onClick={ profileActivate }>⬅</p>
                </div>

{/* 


                <div className={ documentActive ? 'document' : 'document is-right' }>
                  <p>Lorem</p>
                  
                  <div className="document-container">
                    <a href='https://vimeo.com/805484312' target='_blank' rel='noreferrer'><div className="document-1"></div></a>
                    <a href='https://youtu.be/K_F7PsBM74c' target='_blank' rel='noreferrer'><div className="document-2"></div></a>
                  </div>

                  <p className='arrow-back' onClick={ documentActivate }>⬅</p>
                </div> */}




                <div className={ logoActive ? 'logo' : 'logo is-right' }>
                  <p>Uttryk hvem ditt selskap er gjennom en logo</p>
                  
                  <div className="logo-container">
                    <div className="logo-1"></div>
                    <div className="logo-2"></div>
                    <div className="logo-3"></div>
                  </div>

                  <p className='arrow-back' onClick={ logoActivate }>⬅</p>
                </div>











                <button onClick={ triggerGrafisk } className="exit">×</button>
              </div>
            </OutsideClickHandler>
          </motion.div>
       

    );
  
  }
  
  
  