import bg from '../video/bg.mp4';

export default function Footer() {

  return (
    <section className="footer">

      <div className="footer-video">
        <video autoPlay muted loop>
          <source src={bg} type="video/mp4" />
        </video>
      </div>

      <div className="footer-content">
        <p>copyright &copy; ccomm</p>
      </div>
      
    </section>
  );

}



