import OutsideClickHandler from 'react-outside-click-handler';
import { motion } from 'framer-motion';

import hubspot from '../../weblogo/HS.png';
import squarespace from '../../weblogo/SP.png';
import wordpress from '../../weblogo/WP.png';
import webflow from '../../weblogo/WBF.png';
import react from '../../weblogo/R.png';
import next from '../../weblogo/NXT.png';
import vanilla from '../../weblogo/vanilla.png';

export default function Web({triggerWeb}) {
  
    return (

        <motion.div 
        className="popup"
        initial={{ 
          opacity: 0,
          transform: "scale(1.05)",
          filter: "blur(3px)"
         }}
        animate={{ 
          opacity: 1,
          transform: "scale(1)",
          filter: "blur(0)",
          transition: { duration: 0.3 } }}
        exit={{ 
          opacity: 0,
          transform: "scale(1.05)",
          filter: "blur(3px)",
          transition: { duration: 0.3 } } }
        >

            <OutsideClickHandler
              onOutsideClick={() => {
              triggerWeb();
              }}
            >
              <div className="popup-content">
                 <p>Vi tilbyr nettsider lagd i</p>

                  <div className='web-logo'>
                    < img src={wordpress} alt='hubspot' className='icon-1' />
                    < img src={squarespace} alt='hubspot' className='icon-2' />
                    < img src={hubspot} alt='hubspot' className='icon-3' />
                    < img src={webflow} alt='hubspot' className='icon-4' />
                    < img src={react} alt='hubspot' className='icon-5' />
                    < img src={next} alt='hubspot' className='icon-6' />
                    < img src={vanilla} alt='hubspot' className='icon-7' />
                  </div>

                  <button onClick={ triggerWeb }className="exit">×</button>
             </div>
            </OutsideClickHandler>
          </motion.div>
       

    );
  
  }
  
  
  