import logo from '../images/LOGO.png';

export default function Navbar() {

  return (
    <section className="navbar">
      <img src={logo} alt="ccomm logo" />
    </section>
  );

}



