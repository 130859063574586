import { useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { AnimatePresence } from 'framer-motion';


import Film from './popups/Film.js';
import Grafisk from './popups/Grafisk.js';
import Web from './popups/Web.js';

import grafisk from '../images/hvs_mockup.jpg';
import video from '../images/video.jpg';
import dev from '../images/dev.jpg';

export default function Portfolio() {

  const [filmOpen, setFilmOpen] = useState(false);
  const [grafiskOpen, setGrafiskOpen] = useState(false);
  const [webOpen, setWebOpen] = useState(false);

  filmOpen || grafiskOpen || webOpen ? disableBodyScroll(document) : enableBodyScroll(document);

      function triggerFilm() {
      setFilmOpen((current) => !current);
      };

      function triggerGrafisk() {
      setGrafiskOpen((current) => !current);
      };

      function triggerWeb() {
      setWebOpen((current) => !current);
      };

  return (
    <section>

    <div className="text-1">
        <p>Alle har en fortelling. Det være en historie, en idé, et produkt eller en forretningsmodell. Vil du at andre skal forstå hva som ligger på ditt hjerte, må du vite hvordan fortelle en god historie - eller la oss hjelpe deg med det.</p>
    </div>

    <div className="portfolio">

      <div className="card-1">
        <img src={video} alt="video bilde" 
        onClick={triggerFilm} />
        <h2 className='video-title'>Video</h2>
      </div>

      <div className="card-2">
        <img src={grafisk} alt="grafisk bilde" 
        onClick={triggerGrafisk} />
        <h2 className='graph-title'>Grafisk</h2>
      </div>
       
      <div className="card-3">
        <img src={dev} alt="dev bilde"
        onClick={triggerWeb} />
        <h2 className='web-title'>Web</h2>
      </div>

    </div>

      <AnimatePresence>
        { filmOpen ? <Film triggerFilm = {triggerFilm} filmOpen = {filmOpen} /> : null}
      </AnimatePresence>

      <AnimatePresence>
        { grafiskOpen ? <Grafisk triggerGrafisk = {triggerGrafisk} grafiskOpen = {grafiskOpen} /> : null}
      </AnimatePresence>

       <AnimatePresence>
        { webOpen ? <Web triggerWeb = {triggerWeb} webOpen = {webOpen} /> : null}
      </AnimatePresence>
      
    </section>
  );

}



