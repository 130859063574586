import bg from '../video/bg.mp4';

export default function Hero() {

  return (
    <section className="hero">
      <div className="background-video">
        <video autoPlay muted loop>
          <source src={bg} type="video/mp4" />
        </video>
      </div>

      <div className="hero-content">
        <h1>Det var <br/> en gang...</h1>
      </div>
    </section>
  );

}



