import './css/Globals.css';
import './css/Home.css';
import './css/Popups.css';

import Navbar from './sections/Navbar.js';
import Hero from './sections/Hero.js';
import Portfolio from './sections/Portfolio.js';
import About from './sections/About.js';
import Footer from './sections/Footer.js';

import TagManager from 'react-gtm-module'

function App() {

  const tagManagerArgs = {
    gtmId: '<G-PTKSHVS6LS>'
    }
  
    TagManager.initialize(tagManagerArgs)

  return (
    <div>
      < Navbar />
      < Hero />
      < Portfolio />
      < About />
      < Footer />
    </div> 
  );
}

export default App;
